import { Button, useTheme } from '@mui/material';
import { useIntercom } from 'react-use-intercom';
interface FeedbackButtonProps {
}

function FeedbackButton(props: FeedbackButtonProps) {
    const theme = useTheme()
    const { startSurvey } = useIntercom();
    return (
        <Button
            size='small'
            disableRipple
            onClick={(event) => startSurvey(34076676)}
            sx={{
                backgroundColor: theme.palette.appbar.buttonBackground,
                color: theme.palette.appbar.buttonForeground,
                borderRadius: '5px',
                margin: '5px',
                height: '34px',
                '&:hover': {
                    backgroundColor: theme.palette.appbar.buttonBackgroundHover,
                },
            }}
        >Feedback?</Button>
    );
}

export default FeedbackButton;