import { Box, Typography } from '@mui/material';
import { Product } from './ProductSelector';
import { colors } from '../../themes/AppTheme';
import LaunchIcon from '@mui/icons-material/Launch';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";

interface ProductSelectorProductProps {
    product: Product;
}

const handleClick = ( url : string, name: string) => {
 
    ReactGA.event("RT2-PS-Link-" + name);
    window.open(url, "_blank")
};

export default function ProductSelectorProduct(props: ProductSelectorProductProps) {
    const { t } = useTranslation();
    return (
        <Box component="a" onClick={() => handleClick(props.product.url, t(props.product.nameKey))}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'left',
                textAlign: 'left',
                textDecoration: 'none',
                margin: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                borderRadius: '5px',
                border: '1px solid',
                borderColor: colors.ram_light_blue,

                "&:hover": {
                    borderColor: colors.ram_purple,
                    backgroundColor: colors.ram_light_blue,
                },
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', padding: '5px' }}>
                <img
                src={props.product.icon}
                style={{
                    height: "4.5vh",
                    minHeight: "50px",
                    pointerEvents: "none",
                    padding: "1vh",
                }}
                alt="Product Logo"
            />
            </Box>

            <Box sx={{ textAlign: 'left' }}>
                <Typography variant="dropdownBody" sx={{ fontWeight: 'bold' }}>
                    {t(props.product.nameKey)}
                </Typography>
                <Typography variant="dropdownCaption" component='span'>
                    {t(props.product.descriptionKey)}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', padding: '10px', marginLeft: 'auto' }}>
                <Typography variant="dropdownBody">
                    <LaunchIcon sx={{}} />
                </Typography>
            </Box>

        </Box>
    );
}