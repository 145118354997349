import {Box, Tooltip} from '@mui/material';
import React from 'react';
import {Table, TableCell, TableRow} from '../../../components/table/core/Table';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import ConfirmationDialog from '../../../components/dialog/ConfirmationDialog';
import {RowDeleteButton, RowEditButton} from '../../../components/table/core/TableButtons';
import FilterBar from "../../../components/filterbar/FilterBar";
import {useServiceSettingApi} from "../../../hooks/services/serviceSetting/ServiceSettings";
import {ServiceSetting} from "../../../hooks/services/serviceSetting/dto/ServiceSetting";
import {useAssetApi} from "../../../hooks/assets/Assets";

interface ServiceSettingsPageProps {
}

export default function ServiceSettingsPage(props: ServiceSettingsPageProps) {

    const {t} = useTranslation();
    const {serviceSettings, loading, getAllServiceSettings} = useServiceSettingApi({shouldLoadAll: true})
    const {assets} = useAssetApi({shouldLoadAll: true});
    const [searchInput, setSearchInput] = React.useState<string>("");
    const navigate = useNavigate()

    function getFilteredServiceSettings() {
        if (serviceSettings === null) {
            return [];
        } else if (searchInput === undefined || searchInput === "") {
            return serviceSettings || [];
        } else {
            const lowerCaseFilter = searchInput.toLowerCase();
            return serviceSettings.filter(serviceSetting =>
                serviceSetting.recipients.some(recipient => recipient.toLowerCase().includes(lowerCaseFilter)) ||
                getIdentificationMark(serviceSetting.vehicleId).toLowerCase().includes(lowerCaseFilter)
            );
        }
    }

    const getIdentificationMark = (id: number): string => {
        const asset = assets?.find(asset => asset.id === id);
        if (asset) {
            if (asset.alias) {
                return asset.alias;
            } else if (asset.identificationMark) {
                return asset.identificationMark;
            }
        }
        return "";
    }

    const columns = [
        {id: 'period_type', content: t('service.service_settings.service_setting_table.period_type')},
        {id: 'period_value', content: t('service.service_settings.service_setting_table.period_value')},
        {id: 'recipients', content: t('service.service_settings.service_setting_table.recipients')},
        {id: 'asset', content: t('service.service_settings.service_setting_table.asset')},
        {id: 'action', content: t('service.service_settings.service_setting_table.action')}
    ];

    const rows = getFilteredServiceSettings().map((serviceSetting) => {
        return <ServiceSettingRow key={serviceSetting.id} serviceSetting={serviceSetting} updateTrigger={getAllServiceSettings}
                                  getIdentificationMark={getIdentificationMark}/>
    })

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "space-between",
            margin: '30px',
            marginTop: '10px',
            height: '100%',
        }}>
            <FilterBar onSearchChange={setSearchInput} filter={searchInput} label={'Search for assets, recipients...'}/>

            <Table
                id="service-settings-table"
                label="Service Settings"
                columns={columns}
                loading={loading}
                rows={rows}
                createAction={() => {
                    navigate('/servicing/create-service-settings');
                }}
                emptyLabel={t('service.service_settings.service_setting_table.no_service_settings') as string}
            />
        </Box>
    );
};

function ServiceSettingRow(props: { serviceSetting: ServiceSetting, updateTrigger: () => void, getIdentificationMark: (id: number) => string }) {
    const {t} = useTranslation();

    const {deleteServiceSetting} = useServiceSettingApi({shouldLoadAll: true})
    const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false)
    const [selectedServiceSetting, setSelectedServiceSetting] = React.useState<ServiceSetting | null>(null);
    const navigate = useNavigate()

    async function handleDelete() {
        await deleteServiceSetting(selectedServiceSetting?.vehicleId.toString() as string)
            .catch((error) => {
                alert(t('service.service_settings.setting_delete.failed'))
            })
        setShowDeleteDialog(false)
        props.updateTrigger()
    }

    return <TableRow id={props.serviceSetting.id.toString()}>
        <TableCell>
            {t('service.service_settings.service_setting_table.' + props.serviceSetting.periodType.toLowerCase())}
        </TableCell>

        <TableCell>
            {props.serviceSetting.periodValue}
        </TableCell>

        <TableCell>
            {props.serviceSetting.recipients.length > 3 ? (
                    <Tooltip title={props.serviceSetting.recipients.join(', ')} arrow>
                        <>
                            {props.serviceSetting.recipients.length + ' ' + t('service.service_settings.service_setting_table.recipients')}
                        </>
                    </Tooltip>
                ) :
                props.serviceSetting.recipients.map(recipient => (
                    <div key={props.serviceSetting.id.toString() + "_recipients_" + recipient}
                         id={props.serviceSetting.id.toString() + "_recipients_" + recipient}>
                        {recipient} <br/>
                    </div>
                ))
            }
        </TableCell>

        <TableCell>
            {props.getIdentificationMark(props.serviceSetting.vehicleId)}
        </TableCell>

        <TableCell>
            <RowEditButton action={() => {
                const selectedServiceSetting = props.serviceSetting;
                navigate('/servicing/update-service-settings', {state: {selectedServiceSetting: selectedServiceSetting}});
            }}/>
            <RowDeleteButton action={() => {
                setSelectedServiceSetting(props.serviceSetting);
                setShowDeleteDialog(true);
            }}/>

            <ConfirmationDialog open={showDeleteDialog}
                                title={t('service.service_settings.delete_dialog.title')}
                                description={t('service.service_settings.delete_dialog.description')}
                                confirmText={t('button.delete')} cancelText={t('button.cancel')}
                                onConfirm={handleDelete}
                                onCancel={() => setShowDeleteDialog(false)}/>
        </TableCell>
    </TableRow>
}

