import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, TextField, Typography} from "@mui/material";
import {Asset, AssetType, EngineHours} from "../../hooks/assets/dto/Asset";
//Icons
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import SpeedIcon from '@mui/icons-material/Speed';
import PlaceIcon from '@mui/icons-material/Place';
import SignalWifiStatusbarConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import CommuteIcon from '@mui/icons-material/Commute';
import BadgeIcon from '@mui/icons-material/Badge';
import Note from '@mui/icons-material/Note';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import GroupIcon from '@mui/icons-material/Group';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import RouterIcon from '@mui/icons-material/Router';
import MovingIcon from '@mui/icons-material/Moving';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import MinorCrashIcon from '@mui/icons-material/MinorCrash';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';

import {buildLastEventMessage} from "../../utils/AssetUtils";
import {useNavigate} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import LeftPanelButtonBar from "../leftpanel/LeftPanelButtonBar";
import AssetsDetailHeading from "./AssetsDetailSectionHeading";
import React, {useContext, useEffect} from "react";
import {UserPreferencesContext} from "../../providers/UserPreferencesProvider";
import {getStatusColors} from "../../themes/AppTheme";
import AssetDetailStatusHeading from "./AssetDetailStatusHeading";
import {IconValueText} from "../../pages/map/components/IconValueText";
import {mapToVehicleEventTranslation} from "../../utils/TranslationMapper";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {getColorByAssetEvent} from "../../utils/ColorUtils";
import {Battery4Bar, HourglassEmpty, LocalGasStation, Recycling, Thermostat} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {useEngineHoursApi} from "../../hooks/engineHours/EngineHours";
import ConfirmationDialog from "../dialog/ConfirmationDialog";

interface AssetDetailProps {
    asset: Asset;
    onBackPressed: Function;
}

export default function AssetDetail(props: AssetDetailProps) {
    const {t} = useTranslation();

    const navigate = useNavigate()
    const {colorPalette} = useContext(UserPreferencesContext)
    const statusColors = getStatusColors(colorPalette);

    const [engineHoursEnabled, setEngineHoursEnabled] = React.useState<boolean>(false);
    const [showDeleteEngineHoursDialog, setShowDeleteEngineHoursDialog] = React.useState<boolean>(false)
    const [engineHours, setEngineHours] = React.useState<number | undefined>(undefined);
    const [engineHoursInput, setEngineHoursInput] = React.useState<number | undefined>(undefined);
    const {enableEngineHoursForAsset, getAssetEngineHours, updateEngineHoursForAsset, disableEngineHoursForAsset} = useEngineHoursApi();

    const [assetSectionOpen, setAssetSectionOpen] = React.useState(true);
    const [driverSectionOpen, setDriverSectionOpen] = React.useState(true);
    const [trackerSectionOpen, setTrackerSectionOpen] = React.useState(true);
    const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);

    useEffect(() => {
        getAssetEngineHours(props.asset.id)
            .then(updateEngineHoursState)
    }, []);

    function updateEngineHoursState(updatedEngineHours: EngineHours | undefined) {
        if (updatedEngineHours === undefined || updatedEngineHours?.enabled === false || updatedEngineHours?.engineTimeHours === undefined) {
            setEngineHoursEnabled(false)
            setEngineHours(undefined)
        } else {
            setEngineHoursEnabled(true)
            setEngineHours(updatedEngineHours?.engineTimeHours || 0)
        }
    }


    const handleEnableEngineHours = async () => {
        await enableEngineHoursForAsset(props.asset.id)
        const fetchedEngineHours = await getAssetEngineHours(props.asset.id);
        updateEngineHoursState(fetchedEngineHours);
    }

    const handleUpdateEngineHours = async () => {
        const hours = engineHoursInput || 0;
        await updateEngineHoursForAsset(props.asset.id, hours)
        setEngineHours(hours)
        setIsTooltipOpen(false);
    }

    const handleEditClick = () => {
        setIsTooltipOpen(true);
        setEngineHoursInput(engineHours);
    };

    const handleDisableEngineHours = async () => {
        setShowDeleteEngineHoursDialog(false)
        await disableEngineHoursForAsset(props.asset.id)
        setEngineHoursEnabled(false)
        setEngineHours(undefined)
    }

    return (
        <Box component="main" sx={{
            flexGrow: 1, p: 0,
            height: 'calc(-67px + 100vh)', overflow: "hidden",
            overflowY: "scroll",
        }}>
            <LeftPanelButtonBar onBackPressed={props.onBackPressed}/>
            <Box sx={{width: '100%', padding: '10px'}}>

                <table style={{width: '100%'}}>
                    <tbody>
                    <tr>
                        <td style={{textAlign: "left", width: '50%'}}>
                            <Typography
                                component="span"
                                variant="cardtitle"
                                color="primary">
                                {props.asset.alias || props.asset.identificationMark}
                            </Typography>
                        </td>
                        <td style={{textAlign: "right"}}><AssetDetailStatusHeading title={props.asset.status}
                                                                                   titleColor={getColorByAssetEvent(props.asset.latestEvent, statusColors)}/>
                        </td>
                    </tr>
                    <tr>
                        <td style={{textAlign: "left", width: '50%'}}>
                            <Typography
                                component="span"
                                variant="cardbody"
                                color="primary">
                                {'ID ' + props.asset.id}
                            </Typography>
                        </td>

                        <td style={{textAlign: "right"}}>
                            <Typography
                                component="span"
                                variant="cardbody"
                                color="primary">
                                {props.asset.latestEvent ? buildLastEventMessage(props.asset.latestEvent.time) : "-"}
                            </Typography>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </Box>
            <Divider/>

            <Box sx={{width: '100%', padding: '10px'}}>

                {!props.asset.latestEvent &&
                    <IconValueText
                        icon={<AirlineStopsIcon color="primary" style={{fontSize: "18px"}}/>}
                        label={t('asset_details.event_name') as String}
                        value={"-"}/>
                }

                {props.asset.latestEvent && props.asset.latestEvent.customEventName &&
                    <IconValueText
                        icon={<AirlineStopsIcon color="primary" style={{fontSize: "18px"}}/>}
                        label={t('asset_details.event_name') as String}
                        value={getParameterValue(props.asset.latestEvent.customEventName)}/>
                }

                {props.asset.latestEvent && !props.asset.latestEvent.customEventName &&
                    <IconValueText
                        icon={<AirlineStopsIcon color="primary" style={{fontSize: "18px"}}/>}
                        label={t('asset_details.event_name') as String}
                        value={getParameterValue(mapToVehicleEventTranslation(props.asset.latestEvent.type.toString()) ?
                            t('vehicle_event.' + mapToVehicleEventTranslation(props.asset.latestEvent.type.toString())) : props.asset.latestEvent.type)}/>
                }

                <IconValueText
                    icon={<PlaceIcon color="primary" style={{fontSize: "18px"}}/>}
                    label={t('asset_details.location') as String}
                    value={props.asset.latestEvent ? getParameterValue(props.asset.latestEvent.location.snappedCoordinates.latitude +
                        ',' + props.asset.latestEvent.location.snappedCoordinates.longitude) : "-"}
                />

                <IconValueText
                    icon={<SpeedIcon color="primary" style={{fontSize: "18px"}}/>}
                    label={t('asset_details.speed') as String}
                    value={props.asset.latestEvent ? getParameterValue(props.asset.latestEvent.speed.value + ' ' + props.asset.latestEvent.speed.units) : "-"}
                />

                <IconValueText
                    icon={<MovingIcon color="primary" style={{fontSize: "18px"}}/>}
                    label={t('asset_details.direction') as String}
                    value={props.asset.latestEvent ? getParameterValue(props.asset.latestEvent.heading) : "-"}
                />

                <IconValueText
                    icon={<GroupIcon color="primary" style={{fontSize: "18px"}}/>}
                    label={t('asset_details.group') as String}
                    value={getParameterValue(props.asset.groupName)}
                />

                <IconValueText
                    icon={<MultilineChartIcon color="primary" style={{fontSize: "18px"}}/>}
                    label={t('asset_details.geofence') as String}
                    value={props.asset.latestEvent ? getParameterValue(props.asset.latestEvent.location.geofences[0] ?
                        props.asset.latestEvent.location.geofences[0].geofenceName : null) : "-"}
                />

                <IconValueText
                    icon={<CropOriginalIcon color="primary" style={{fontSize: "18px"}}/>}
                    label={t('asset_details.congestion_zone') as String}
                    value={props.asset.latestEvent ? getParameterValue(props.asset.latestEvent.location.zoneName) : "-"}
                />

                <IconValueText
                    icon={<SignalWifiStatusbarConnectedNoInternet4Icon color="primary" style={{fontSize: "18px"}}/>}
                    label={t('asset_details.gps_signal') as String}
                    value={props.asset.latestEvent?.location?.goodGpsFix ?
                            t('asset_details.good_gps_signal_label') as String : t('asset_details.poor_gps_signal_label') as String}
                />

                <IconValueText
                    icon={<LocationOnIcon color="primary" style={{fontSize: "18px"}}/>}
                    label={t('asset_details.address') as string}
                    value={props.asset.latestEvent ? getParameterValue(props.asset.latestEvent.location.formattedAddress) : "-"}
                />

            </Box>

            <Box sx={{width: '100%'}}>
                <Box sx={{width: '100%', padding: '10px'}}>
                    <Button fullWidth color="secondary" variant="contained" onClick={() => {
                        navigate('/route-replay?selected=' + props.asset.id)
                    }}>
                        Route Replay
                    </Button>
                </Box>
            </Box>

            <Box sx={{width: '100%'}}>
                <AssetsDetailHeading title="Asset" open={assetSectionOpen} onClick={() => {
                    setAssetSectionOpen(!assetSectionOpen)
                }}/>
                {assetSectionOpen ?
                    <Box sx={{width: '100%', padding: '10px'}}>

                        <IconValueText
                            icon={<CommuteIcon color="primary" style={{fontSize: "18px"}}/>}
                            label={t('asset_details.type') as String}
                            value={getParameterValue(props.asset.type)}
                        />

                        <IconValueText
                            icon={<PrecisionManufacturingIcon color="primary" style={{fontSize: "18px"}}/>}
                            label={t('asset_details.make') as String}
                            value={getParameterValue(props.asset.make)}
                        />

                        <IconValueText
                            icon={<DirectionsCarIcon color="primary" style={{fontSize: "18px"}}/>}
                            label={t('asset_details.model') as String}
                            value={getParameterValue(props.asset.model)}
                        />

                        <IconValueText
                            icon={<CalendarMonthIcon color="primary" style={{fontSize: "18px"}}/>}
                            label={t('asset_details.year') as String}
                            value={getParameterValue(props.asset.year)}
                        />

                        <IconValueText
                            icon={<ColorLensIcon color="primary" style={{fontSize: "18px"}}/>}
                            label={t('asset_details.colour') as String}
                            value={getParameterValue(props.asset.colour)}
                        />

                        <IconValueText
                            icon={<DriveFileRenameOutlineIcon color="primary" style={{fontSize: "18px"}}/>}
                            label={t('asset_details.alias') as String}
                            value={getParameterValue(props.asset.alias)}
                        />

                        <IconValueText
                            icon={<BadgeIcon color="primary" style={{fontSize: "18px"}}/>}
                            label={t('asset_details.identification_mark') as String}
                            value={getParameterValue(props.asset.identificationMark)}
                        />

                        <IconValueText
                            icon={<GroupIcon color="primary" style={{fontSize: "18px"}}/>}
                            label={t('asset_details.group') as String}
                            value={getParameterValue(props.asset.groupName)}
                        />

                        {props.asset.latestEvent?.batteryPercentage !== undefined && props.asset.latestEvent?.batteryPercentage !== null ? (
                            <IconValueText
                                icon={<Battery4Bar color="primary" style={{fontSize: "18px"}}/>}
                                label={t('asset_card.battery_percentage') as String}
                                value={getParameterValue(props.asset.latestEvent.batteryPercentage) + '%'}
                            />
                        ) : <></>}

                        {props.asset.latestEvent?.temperature1 !== undefined && props.asset.latestEvent?.temperature1 !== null ? (
                            <IconValueText
                                icon={<Thermostat color="primary" style={{fontSize: "18px"}}/>}
                                label={t('asset_card.temperature1') as String}
                                value={getParameterValue(props.asset.latestEvent.temperature1) + '°C'}
                            />
                        ) : <></>}

                        {props.asset.latestEvent?.temperature2 !== undefined && props.asset.latestEvent?.temperature2 !== null ? (
                            <IconValueText
                                icon={<Thermostat color="primary" style={{fontSize: "18px"}}/>}
                                label={t('asset_card.temperature2') as String}
                                value={getParameterValue(props.asset.latestEvent.temperature2) + '°C'}
                            />
                        ) : <></>}

                        {props.asset.type === AssetType.GENERATOR ? (
                                <>
                                    <IconValueText
                                        icon={<SpeedIcon color="primary" style={{fontSize: "18px"}}/>}
                                        label={t('asset_details.running_hours') as string}
                                        value={props.asset.latestEvent ? getParameterValue(props.asset.latestEvent.odometer) : "-"}
                                    />
                                    <IconValueText
                                        icon={<LocalGasStation color="primary" style={{fontSize: "18px"}}/>}
                                        label={t('asset_details.fuel_level') as string}
                                        value={props.asset.latestEvent ? getParameterValue(props.asset.latestEvent.fuelLevel) + '%' : "-"}
                                    />
                                    <IconValueText
                                        icon={<Recycling color="primary" style={{fontSize: "18px"}}/>}
                                        label={t('asset_details.waste_level') as string}
                                        value={props.asset.latestEvent ? getParameterValue(props.asset.latestEvent.wasteLevel) + '%' : "-"}
                                    />
                                </>
                            ) :
                            <IconValueText
                                icon={<SpeedIcon color="primary" style={{fontSize: "18px"}}/>}
                                label={t('asset_details.odometer') as String}
                                value={props.asset.latestEvent ? getParameterValue(props.asset.latestEvent.odometer) : "-"}
                            />}
                        {!engineHoursEnabled ? (
                            <IconValueText
                                icon={<HourglassEmpty color="primary" style={{fontSize: "18px"}}/>}
                                label='Engine Hours'
                                value={
                                    <Button
                                        variant="contained"
                                        size='small'
                                        color="primary"
                                        onClick={handleEnableEngineHours}
                                    >
                                        {t("button.enable")}
                                    </Button>
                                }
                            />
                        ) : (
                            <IconValueText
                                icon={<HourglassEmpty color="primary" style={{fontSize: "18px"}}/>}
                                label={t('asset_details.engine_hours') as String}
                                value={
                                    <Box display="flex" justifyContent="space-between" alignItems="center">

                                        {formatDecimalPlaces(engineHoursEnabled ?
                                            engineHours : "-")}

                                        <IconButton
                                            size='small'
                                            color="primary"
                                            onClick={handleEditClick}
                                            style={{marginRight: '5px', marginLeft: '10px'}}
                                        >
                                            <EditIcon style={{fontSize: "18px"}}/>
                                        </IconButton>


                                        <IconButton
                                            size='small'
                                            color="primary"
                                            onClick={() => setShowDeleteEngineHoursDialog(true)}
                                            style={{marginRight: '0px'}}
                                        >
                                            <DeleteIcon style={{fontSize: "18px"}}/>
                                        </IconButton>


                                        <ConfirmationDialog open={showDeleteEngineHoursDialog}
                                                            title={t('asset_details.disable_engine_hours')}
                                                            description={t('asset_details.disable_engine_hours_description')}
                                                            confirmText={t('button.delete')} cancelText={t('button.cancel')}
                                                            onConfirm={handleDisableEngineHours}
                                                            onCancel={() => setShowDeleteEngineHoursDialog(false)}/>

                                        <Dialog open={isTooltipOpen} onClose={() => setIsTooltipOpen(false)}>
                                            <DialogTitle>{t('asset_details.edit_engine_hours')}</DialogTitle>
                                            <DialogContent>
                                                <TextField
                                                    type="Number"
                                                    value={engineHoursInput || ""}
                                                    onChange={(e) => {
                                                        if (!e.target.value || /^[0-9]+$/.test(e.target.value)) {
                                                            setEngineHoursInput(e.target.value ? Number(e.target.value) : undefined);
                                                        }
                                                    }}
                                                />
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleUpdateEngineHours} color="primary">
                                                    {t('button.confirm')}
                                                </Button>
                                            </DialogActions>
                                        </Dialog>


                                    </Box>
                                }
                            />
                        )}


                        <IconValueText
                            icon={<Note color="primary" style={{fontSize: "18px"}}/>}
                            label={t('asset_details.notes') as String}
                            value={getParameterValue(props.asset.notes)}
                        />
                    </Box>
                    : <></>
                }
            </Box>

            <Box sx={{width: '100%'}}>
                <AssetsDetailHeading title="Driver/Assignee" open={driverSectionOpen} onClick={() => {
                    setDriverSectionOpen(!driverSectionOpen)
                }}/>
                {driverSectionOpen ?
                    <Box sx={{width: '100%', padding: '10px'}}>

                        <IconValueText
                            icon={<MinorCrashIcon color="primary" style={{fontSize: "18px"}}/>}
                            label={t('asset_details.type') as String}
                            value={getParameterValue(props.asset.type === AssetType.CAR ||
                            props.asset.type === AssetType.VAN ||
                            props.asset.type === AssetType.TRUCK ||
                            props.asset.type === AssetType.MOTORCYCLE ? "Driver" : "Asset")}
                        />

                        <IconValueText
                            icon={<PersonIcon color="primary" style={{fontSize: "18px"}}/>}
                            label={t('asset_details.name') as String}
                            value={getParameterValue(props.asset.assignee.name)}
                        />

                        <IconValueText
                            icon={<PhoneIcon color="primary" style={{fontSize: "18px"}}/>}
                            label={t('asset_details.contact_number') as String}
                            value={getParameterValue(props.asset.assignee.mobileNumber)}
                        />

                    </Box>
                    : <></>
                }
            </Box>

            <Box sx={{width: '100%'}}>
                <AssetsDetailHeading title="Tracker" open={trackerSectionOpen} onClick={() => {
                    setTrackerSectionOpen(!trackerSectionOpen)
                }}/>
                {trackerSectionOpen ?
                    <Box sx={{width: '100%', padding: '10px'}}>

                        <IconValueText
                            icon={<RouterIcon color="primary" style={{fontSize: "18px"}}/>}
                            label={t('asset_details.unit_type') as String}
                            value={props.asset.unit ? getParameterValue(props.asset.unit.type) : "-"}
                        />

                        <IconValueText
                            icon={<FingerprintIcon color="primary" style={{fontSize: "18px"}}/>}
                            label={t('asset_details.imei') as String}
                            value={props.asset.unit ? getParameterValue(props.asset.unit.imei) : "-"}
                        />

                    </Box>
                    : <></>
                }
            </Box>
        </Box>

    );
}

export function getParameterValue<T>(parameter: T): String {
    return (parameter !== undefined && parameter !== null ? parameter : "-") as String;
}

export function formatDecimalPlaces<T>(parameter: T): string {
    if (typeof parameter === 'number') {
        return parameter.toFixed(1);
    }
    return "-";
}

