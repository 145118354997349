import {Box, Button, Divider, Grid, Paper, Typography, useTheme} from "@mui/material";

import {useTranslation} from "react-i18next";

import React, {useContext, useState} from "react";
import {FilterSortContext} from "../../../providers/FilterSortProvider";
import {Asset} from "../../../hooks/assets/dto/Asset";
import {useAssetApi} from "../../../hooks/assets/Assets";
import {useLocation, useNavigate} from "react-router-dom";
import ServicePeriodTypeSelection from "../shared/ServicePeriodTypeSelection";
import {ServicePeriodType} from "../../../enum/ServicePeriodType";
import TextInput from "../../../components/form/TextInput";
import {useServiceSettingApi} from "../../../hooks/services/serviceSetting/ServiceSettings";
import {ServiceSettingRequest} from "../../../hooks/services/serviceSetting/dto/ServiceSettingRequest";
import {SelectorLabel} from "../../reports/shared/SelectorLabel";
import ServiceAssetSelector from "../shared/ServiceAssetSelector";

export default function ServiceSettingsPageEditor() {
    const theme = useTheme();
    const {t} = useTranslation();
    const navigate = useNavigate()

    const location = useLocation();
    const isState = location.state !== undefined && location.state !== null;
    const isEdit = isState && location.state.selectedServiceSetting !== null && location.state.selectedServiceSetting !== undefined;
    const selectedServiceSetting = isEdit ? location.state.selectedServiceSetting : undefined;

    const {sort} = useContext(FilterSortContext);

    const {assets, loading: loadingAssets, getAllAssets} = useAssetApi({shouldLoadAll: true});
    const {createServiceSetting, updateServiceSetting, serviceSettings} = useServiceSettingApi({shouldLoadAll: true});

    const [selectedAssetRows, setSelectedAssetRows] = useState<number[]>([]);
    const [selectedServicePeriodType, setSelectedServicePeriodType] = useState(isEdit ? selectedServiceSetting?.periodType : ServicePeriodType.ENGINE_HOUR);
    const [selectedServicePeriodValue, setSelectedServicePeriodValue] = useState<number>(isEdit ? selectedServiceSetting?.periodValue : 0);
    const [email1, setEmail1] = useState<string>(isEdit ? selectedServiceSetting?.recipients[0] : '')
    const [email2, setEmail2] = useState<string>(isEdit ? selectedServiceSetting?.recipients[1] : '')
    const [email3, setEmail3] = useState<string>(isEdit ? selectedServiceSetting?.recipients[2] : '')

    const handlePeriodValueChange = (value: string) => {
        const numberValue = parseFloat(value);
        setSelectedServicePeriodValue(numberValue);
    };

    const handleCreateServiceSetting = async () => {

        await createServiceSetting(new ServiceSettingRequest({
            periodType: selectedServicePeriodType,
            periodValue: selectedServicePeriodValue,
            vehicleId: selectedAssetRows[0],
            recipients: [email1, email2, email3].filter(email => email && email.trim().length > 0),
        }))
            .then((serviceSetting) => {
                navigate('/servicing/service-settings')
            })
            .catch((error) => {
                alert(t('service.service_settings.setting_create.failed'))
            })
    }

    const handleUpdateServiceSetting = async () => {

        await updateServiceSetting(new ServiceSettingRequest({
            periodType: selectedServicePeriodType,
            periodValue: selectedServicePeriodValue,
            vehicleId: selectedAssetRows[0],
            recipients: [email1, email2, email3].filter(email => email && email.trim().length > 0),
        }))
            .then((serviceSetting) => {
                navigate('/servicing/service-settings')
            })
            .catch((error) => {
                alert(t('service.service_settings.setting_update.failed'))
            })
    }

    const handleCancel = () => {
        navigate('/servicing/service-settings')
    }

    React.useEffect(() => {
        async function getAssets() {

            let assetList = await getAllAssets() as Asset[];

            if (assetList) {
                let selectedRowsList = assetList.filter(asset => {
                    return selectedServiceSetting?.vehicleId === asset.id;
                }).map(asset => asset.id);

                setSelectedAssetRows(selectedRowsList)
            }
        }

        if (isEdit) {
            getAssets()
        }

    }, []);


    return (
        <Box
            component="main"
            sx={{
                // padding: '10px',
                width: '100%',
                height: '100%',
                backgroundColor: theme.palette.contentPage.background
            }}
        >
            <Grid container component={Paper} sx={{padding: '10px', height: '100%', width: '100%'}} elevation={3}>

                {/* Service Period Type Selection */}

                <Grid container>
                    <SelectorLabel
                        title={t("service.service_settings.editor.period_type")}
                        subtitle={t("service.service_settings.editor.select_period_type")}
                    />
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <ServicePeriodTypeSelection selectedServicePeriodType={selectedServicePeriodType}
                                                    setSelectedServicePeriodType={setSelectedServicePeriodType}/>
                    </Grid>
                </Grid>

                <Divider sx={{marginLeft: '20px', marginRight: '20px'}}/>

                {/* Service Period Value Selection */}

                <Grid container>
                    <SelectorLabel
                        title={t("service.service_settings.editor.period_value")}
                        subtitle={t("service.service_settings.editor.set_period_value")}
                    />
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <TextInput editMode={true} isNumber={true}
                                   label={''} required={true} placeholder={''}
                                   value={selectedServicePeriodValue.toString()} onChange={handlePeriodValueChange}/>
                    </Grid>
                </Grid>

                {/* Email 1 Selection */}

                <Grid container>
                    <SelectorLabel
                        title={t("service.service_settings.editor.email_1")}
                        subtitle={t("service.service_settings.editor.enter_email_1")}
                    />
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <TextInput editMode={true} isEmail={true}
                                   label={''} required={false} placeholder={''}
                                   value={email1} onChange={setEmail1}/>
                    </Grid>
                </Grid>

                {/* Email 2 Selection */}

                <Grid container>
                    <SelectorLabel
                        title={t("service.service_settings.editor.email_2")}
                        subtitle={t("service.service_settings.editor.enter_email_2")}
                    />
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <TextInput editMode={true} isEmail={true}
                                   label={''} required={false} placeholder={''}
                                   value={email2} onChange={setEmail2}/>
                    </Grid>
                </Grid>

                {/* Email 3 Selection */}

                <Grid container>
                    <SelectorLabel
                        title={t("service.service_settings.editor.email_3")}
                        subtitle={t("service.service_settings.editor.enter_email_3")}
                    />
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <TextInput editMode={true} isEmail={true}
                                   label={''} required={false} placeholder={''}
                                   value={email3} onChange={setEmail3}/>
                    </Grid>
                </Grid>

                {/* Asset Selection */}

                <Grid container>
                    <Grid item xs={12} md={4} lg={2}>
                        <Typography sx={{marginLeft: "20px", marginTop: "10px"}}
                                    variant="title">{t("service.service_settings.editor.assets")}</Typography>
                        <Typography sx={{marginLeft: "20px"}} variant="body">{t("service.service_settings.editor.select_assets")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={10} padding="20px">
                        <ServiceAssetSelector isEdit={isEdit}
                                              loadingAssets={loadingAssets}
                                              assets={assets}
                                              selectedAssetRows={selectedAssetRows}
                                              setSelectedAssetRows={setSelectedAssetRows}
                                              label={t("service.service_settings.editor.asset_search_box_label")}
                                              sort={sort}
                                              serviceSettings={serviceSettings}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                        <Box
                            bottom="0px"
                            sx={{
                                padding: '10px',
                                display: 'flex', flexDirection: "row", justifyContent: "space-between"
                            }}>
                            <Button
                                variant="contained"
                                size='small'
                                color="secondary"
                                onClick={() => {
                                    handleCancel()
                                }}>
                                {t('button.cancel')}
                            </Button>
                            <Button
                                disabled={selectedAssetRows.length === 0 || selectedServicePeriodValue <= 0 || (email1 === '' && email2 === '' && email3 === '')}
                                variant="contained"
                                size='small'
                                color="primary"
                                onClick={isEdit ? handleUpdateServiceSetting : handleCreateServiceSetting}
                            >
                                {isEdit ? t("button.edit") : t("button.create")}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};
