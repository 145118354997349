import React from "react";
import SecondaryNavigationPanel from "../../components/secondary_navigation_panel/SecondaryNavigationPanel";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import SplitView from "../../layouts/SplitView";
import ServicesContentPanel from "./ServicesContentPanel";

interface ServicesPageProps {
}

function ServicesPage(props: ServicesPageProps) {
    const {t} = useTranslation();
    const {pathname} = useLocation()

    const secondaryNavItems = [
        {
            text: t("service.service_navigation_panel.service_settings"),
            selected: pathname === '/servicing' || pathname === '/servicing/service-settings',
            to: 'service-settings'
        },
        {
            text: t("service.service_navigation_panel.service_records"),
            selected: pathname === '/records' || pathname === '/servicing/service-records',
            to: 'service-records'
        }
    ]

    const secondaryNav = <SecondaryNavigationPanel title={t("service.service_navigation_panel.title") as String} items={secondaryNavItems}/>

    const content = <ServicesContentPanel/>

    return (
        <SplitView scondaryNavigation={secondaryNav} content={content}/>
    );
}

export default React.memo(ServicesPage, (prevProps, nextProps) => true);
