import {ServicePeriodType} from "../../../../enum/ServicePeriodType";

export class ServiceSettingRequest {
    vehicleId: number;
    periodType: ServicePeriodType;
    periodValue: number;
    recipients: string[] = [];

    constructor(props: {
        vehicleId: number;
        periodType: ServicePeriodType;
        periodValue: number;
        recipients: string[];
    }) {
        this.vehicleId = props.vehicleId;
        this.periodType = props.periodType;
        this.periodValue = props.periodValue;
        this.recipients = props.recipients;
    }
}
