import {Box} from '@mui/material';
import React from 'react';
import {Table, TableCell, TableRow} from '../../../components/table/core/Table';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import ConfirmationDialog from '../../../components/dialog/ConfirmationDialog';
import {RowDeleteButton, RowEditButton} from '../../../components/table/core/TableButtons';
import FilterBar from "../../../components/filterbar/FilterBar";
import {useAssetApi} from "../../../hooks/assets/Assets";
import {useServiceRecordApi} from "../../../hooks/services/serviceRecord/ServiceRecords";
import {ServiceRecord} from "../../../hooks/services/serviceRecord/dto/ServiceRecord";

interface ServiceRecordsPageProps {
}

export default function ServiceRecordsPage(props: ServiceRecordsPageProps) {

    const {t} = useTranslation();
    const {serviceRecords, loading, getAllServiceRecords} = useServiceRecordApi({shouldLoadAll: true})
    const {assets} = useAssetApi({shouldLoadAll: true});
    const [searchInput, setSearchInput] = React.useState<string>("");
    const navigate = useNavigate()

    function getFilteredServiceRecords() {
        if (serviceRecords === null) {
            return [];
        } else if (searchInput === undefined || searchInput === "") {
            return serviceRecords || [];
        } else {
            const lowerCaseFilter = searchInput.toLowerCase();
            return serviceRecords.filter(serviceRecord =>
                serviceRecord.notes?.toLowerCase().includes(lowerCaseFilter) ||
                getIdentificationMark(serviceRecord.vehicleId).toLowerCase().includes(lowerCaseFilter)
            );
        }
    }

    const getIdentificationMark = (id: number): string => {
        const asset = assets?.find(asset => asset.id === id);
        if (asset) {
            if (asset.alias) {
                return asset.alias;
            } else if (asset.identificationMark) {
                return asset.identificationMark;
            }
        }
        return "";
    }

    const columns = [
        {id: 'asset', content: t('service.service_records.service_record_table.asset')},
        {id: 'engine_hours', content: t('service.service_records.service_record_table.engine_hours')},
        {id: 'miles', content: t('service.service_records.service_record_table.miles')},
        {id: 'completed_at', content: t('service.service_records.service_record_table.completed_at')},
        {id: 'action', content: t('service.service_records.service_record_table.action')}
    ];

    const rows = getFilteredServiceRecords().map((serviceRecord) => {
        return <ServiceRecordRow key={serviceRecord.id} serviceRecord={serviceRecord} updateTrigger={getAllServiceRecords}
                                 getIdentificationMark={getIdentificationMark}/>
    })

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "space-between",
            margin: '30px',
            marginTop: '10px',
            height: '100%',
        }}>
            <FilterBar onSearchChange={setSearchInput} filter={searchInput} label={'Search for assets, notes...'}/>

            <Table
                id="service-records-table"
                label="Service Records"
                columns={columns}
                loading={loading}
                rows={rows}
                createAction={() => {
                    navigate('/servicing/create-service-record');
                }}
                emptyLabel={t('service.service_records.service_record_table.no_service_records') as string}
            />
        </Box>
    );
};

function ServiceRecordRow(props: { serviceRecord: ServiceRecord, updateTrigger: () => void, getIdentificationMark: (id: number) => string }) {
    const {t} = useTranslation();

    const {deleteServiceRecord} = useServiceRecordApi({shouldLoadAll: true})
    const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false)
    const [selectedServiceRecord, setSelectedServiceRecord] = React.useState<ServiceRecord | null>(null);
    const navigate = useNavigate()

    async function handleDelete() {
        await deleteServiceRecord(selectedServiceRecord?.vehicleId.toString() as string, selectedServiceRecord?.id.toString() as string)
            .catch((error) => {
                alert(t('service.service_record.record_delete.failed'))
            })
        setShowDeleteDialog(false)
        props.updateTrigger()
    }

    return <TableRow id={props.serviceRecord.id.toString()}>
        <TableCell>
            {props.getIdentificationMark(props.serviceRecord.vehicleId)}
        </TableCell>

        <TableCell>
            {props.serviceRecord.engineHours}
        </TableCell>

        <TableCell>
            {props.serviceRecord.miles}
        </TableCell>

        <TableCell>
            {props.serviceRecord.completedAt}
        </TableCell>

        <TableCell>
            {<RowEditButton action={() => {
                const selectedServiceRecord = props.serviceRecord;
                navigate('/servicing/update-service-record', {state: {selectedServiceRecord: selectedServiceRecord}});
            }}/>}
            <RowDeleteButton action={() => {
                setSelectedServiceRecord(props.serviceRecord);
                setShowDeleteDialog(true);
            }}/>

            <ConfirmationDialog open={showDeleteDialog}
                                title={t('service.service_records.delete_dialog.title')}
                                description={t('service.service_records.delete_dialog.description')}
                                confirmText={t('button.delete')} cancelText={t('button.cancel')}
                                onConfirm={handleDelete}
                                onCancel={() => setShowDeleteDialog(false)}/>
        </TableCell>
    </TableRow>
}

