import { Box, List } from "@mui/material";
import { Asset } from "../../../../hooks/assets/dto/Asset";
import React, { useContext } from "react";
import RouteReplayAssetCard from "./RouteReplayAssetCard";
import ListSectionHeading from "../../../../components/leftpanel/ListSectionHeading";
import AssetsFilterBar from "../../../../components/leftpanel/AssetFilterBar";
import { FilterSortContext } from "../../../../providers/FilterSortProvider";
import { AssetSortOptions, sortByDriverName, sortByLastEventTime } from "../../../../utils/AssetArraySorting";
import { filterAssetsBySearch } from "../../../../utils/AssetArrayFiltering";
import LoadingSpinner from "../../../../components/loadingspinner/LoadingSpinner";
import ReactGA from "react-ga4";

interface RouteReplayProps {
    loading: boolean;
    assets: Array<Asset>;
    setSelectedAsset: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export default function RouteReplayAssetsList(props: RouteReplayProps) {
    const [filter, setFilter] = React.useState<string | undefined>(undefined);
    const { sort, groupFilter, typeFilter } = useContext(FilterSortContext);

    const onSearchChange = (search: string) => {
        if (search === undefined || search === "") {
            setFilter(undefined);
        } else {
            ReactGA.event("search",  {search_term: "route-replay-" + search});
            setFilter(search);
        }
    }

    const filterAssets = (assets: Array<Asset>) => {
        let filteredAssets = filterAssetsBySearch(assets, filter);

        switch (sort) {
            case AssetSortOptions.DRIVER_NAME:
                filteredAssets.sort(sortByDriverName);
                break;
            case AssetSortOptions.LAST_REPORTED:
                filteredAssets.sort(sortByLastEventTime);
                break;
            default:
                break;
        }

        if (groupFilter !== "all") {
            filteredAssets = filteredAssets.filter(asset => asset.groupName === groupFilter);
        }
        if (typeFilter !== "all") {
            filteredAssets = filteredAssets.filter(asset => asset.type === typeFilter);
        }
        return filteredAssets;
    }

    const clickedVehicle = (asset: Asset | undefined) => {

        ReactGA.event("RT2-RouteReplay-SelectedVehicle");
        props.setSelectedAsset(asset === undefined ? undefined : asset.id.toString())

    }

    return (
        <Box component="main" sx={{
            flexGrow: 1, p: 0,
            height: 'calc(-48px + 100vh)', overflow: "hidden",
            overflowY: "scroll",
        }}>

            <AssetsFilterBar onSearchChange={onSearchChange} assets={props.assets} />
            {props.loading && <Box sx={{ textAlign: "center", padding: "20px" }}><LoadingSpinner /></Box>}
            {!props.loading && <List
                dense={true}
                sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
                <ListSectionHeading title={"Assets"} />
                {filterAssets(props.assets).map((asset) => {
                    return (<RouteReplayAssetCard key={asset.id} asset={asset} clickedVehicle={clickedVehicle} />)
                })}
            </List>}
        </Box>
    );
}