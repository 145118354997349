import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import AppsRounded from '@mui/icons-material/AppsRounded';
import { useTranslation } from 'react-i18next';
import AppBarButton from './AppBarButton';
import { Typography } from '@mui/material';
import ProductSelectorProduct from './ProductSelectorProduct';
import useAccountApi from '../../hooks/account/Account';
import ReactGA from "react-ga4";

export interface Product {
    nameKey: string;
    descriptionKey: string;
    icon: string;
    url: string;
}

const GBPRODUCTS: Product[] = [
    {
        nameKey: 'product_selector.assist.title',
        descriptionKey: 'product_selector.assist.description',
        icon: '/images/logos/ram-assist-logo.png',
        url: 'https://go.ramtracking.com/l/28952/2022-07-06/9mfpj5'
    },
    {
        nameKey: 'product_selector.live.title',
        descriptionKey: 'product_selector.live.description',
        icon: '/images/logos/ram-live-logo.png',
        url: 'https://go.ramtracking.com/l/28952/2022-07-06/9mfpjc'
    },
    {
        nameKey: 'product_selector.marketplace.title',
        descriptionKey: 'product_selector.marketplace.description',
        icon: '/images/logos/ram-marketplace-logo.png',
        url: 'https://go.ramtracking.com/l/28952/2022-07-06/9mfpjg'
    },
];
const USPRODUCTS: Product[] = [
    {
        nameKey: 'product_selector.assist.title',
        descriptionKey: 'product_selector.assist.description',
        icon: '/images/logos/ram-assist-logo.png',
        url: 'https://go.ramtracking.com/l/28952/2022-07-06/9mfpj5'
    },
    {
        nameKey: 'product_selector.live.title',
        descriptionKey: 'product_selector.live.description',
        icon: '/images/logos/ram-live-logo.png',
        url: 'https://go.ramtracking.com/l/28952/2022-07-06/9mfpjc'
    },
];
const CAPRODUCTS: Product[] = [
    {
        nameKey: 'product_selector.assist.title',
        descriptionKey: 'product_selector.assist.description',
        icon: '/images/logos/ram-assist-logo.png',
        url: 'https://go.ramtracking.com/l/28952/2022-07-06/9mfpj5'
    },
    {
        nameKey: 'product_selector.live.title',
        descriptionKey: 'product_selector.live.description',
        icon: '/images/logos/ram-live-logo.png',
        url: 'https://go.ramtracking.com/l/28952/2022-07-06/9mfpjc'
    },
];

export default function ProductSelector() {
    const { t } = useTranslation();
    const { account } = useAccountApi();

    let products: Product[] = [];

    switch (account?.country) {
        case 'GB': products = GBPRODUCTS; break;
        case 'US': products = USPRODUCTS; break;
        case 'CA': products = CAPRODUCTS; break;
        default: products = []; break;
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        ReactGA.event("RT2-OpenProductSelector");
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', textAlign: 'center' }}>
                <AppBarButton
                    id='product-selector-button'
                    icon={<AppsRounded />}
                    onClick={handleClick}
                    ariaControls={open ? 'account-menu' : undefined}
                    ariaHasPopup={true}
                    ariaExpanded={open ? true : undefined}
                />
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        width: "450px",
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 45,
                            height: 45,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Box display="flex" flexDirection="column" alignItems="left"
                    sx={{
                        width: '100%',
                        marginTop: '-5px',
                        paddingLeft: '15px',
                        paddingBottom: '5px',
                    }}>
                    <Typography variant="dropdownTitle">
                        {t('product_selector.title')}
                    </Typography>
                    <Typography variant="dropdownBody">
                        {t('product_selector.subtitle')}
                    </Typography>
                </Box>
                <Divider />

                {products.map((product) => (
                    <ProductSelectorProduct key={product.nameKey} product={product} />
                ))}

            </Menu>
        </React.Fragment>
    );
}
