import { useTranslation } from "react-i18next";
import { Box, Divider, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';

interface DataAttributionPageProps {
}

export default function DataAttributionPage(props: DataAttributionPageProps) {
  const { t } = useTranslation();

  return (
    <Box sx={{
      display: 'flex', flexDirection: "column", flexGrow: 1, justifyContent: "space-between"
  }}>
      <Box sx={{
          padding: '30px',
          paddingTop: '10px',
      }}>
          <Typography variant="header" sx={{ paddingBottom: "30px" }}>
              {t('dataattribution_page.navigation.title')}
          </Typography>
          <Divider />

          <Typography variant="title" >
              {t('dataattribution_page.navigation.dataAttributiontitle')}
          </Typography>
          <Typography variant="body">
            {t('dataattribution_page.navigation.body')} <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noreferrer" >OpenStreetMap</a>.
          </Typography>
          </Box>
  <Outlet />
</Box>
  );
}