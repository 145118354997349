
import { Divider, ListItemButton, Box, Typography } from '@mui/material';
import { Asset } from '../../../../hooks/assets/dto/Asset';
import PersonIcon from '@mui/icons-material/Person';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import NearMeIcon from '@mui/icons-material/NearMe';
import { AssetCardIconText } from '../../../../components/assetpanel/AssetCardIconText';
import { buildAssetStatusMessage } from '../../../../utils/AssetUtils';
import { useContext } from "react";
import { UserPreferencesContext } from "../../../../providers/UserPreferencesProvider";

interface RouteReplayAssetCardProps {
  asset: Asset;
  clickedVehicle: Function
}

function RouteReplayAssetCard(props: RouteReplayAssetCardProps) {
  const { twentyFourHourEnabled } = useContext(UserPreferencesContext)
  const handleListItemClick = (
    asset: Asset,
  ) => {
    props.clickedVehicle(asset)
  };

  return (
    <>
      <ListItemButton alignItems="flex-start" onClick={(event) => handleListItemClick(props.asset)}>
        <Box>
          <Typography
            sx={{ display: "block", width: "100%" }}
            component="div"
            variant="cardtitle"
            color="text.primary"
          >
            {props.asset.alias || props.asset.identificationMark}
          </Typography>

          <AssetCardIconText icon={<LocationOnIcon fontSize="inherit" />} value={props.asset.latestEvent?.location.formattedAddress || ""} />
          <AssetCardIconText icon={<NearMeIcon fontSize="inherit" />} value={buildAssetStatusMessage(props.asset)} />
          <AssetCardIconText icon={<PersonIcon fontSize="inherit" />} value={props.asset.assignee.name} />
          {props.asset.groupName !== null && <AssetCardIconText icon={<WorkspacesIcon fontSize="inherit" />} value={props.asset.groupName!} />}
          <AssetCardIconText icon={<AccessTimeFilledIcon fontSize="inherit" />} value={props.asset.latestEvent ? props.asset.latestEvent.time.toLocaleString('en-GB', { year: 'numeric', month: "numeric", day: 'numeric', hour: "numeric", minute: "numeric", second: "numeric", hour12: !twentyFourHourEnabled }).toUpperCase() : ""} />

        </Box>
      </ListItemButton>
      <Divider component="li" />
    </>
  );
}

export default RouteReplayAssetCard;
