import {useContext, useEffect, useState} from "react";
//MUI style and component imports
import AppBar, {AppBarProps} from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {Box, styled, useMediaQuery, useTheme} from "@mui/material";

//Import properties loader & Load Props
import SearchBar from "./search/SearchBar";
import AccountMenu from "./AccountMenu";
import ProductSelector from "./ProductSelector";
import LanguageSelector from "./LanguageSelector";
import {drawerWidth} from "../appdrawer/AppDrawer";
import {UserPreferencesContext} from "../../providers/UserPreferencesProvider";
import AppBarButton from "./AppBarButton";
import MobileAppDrawer from "../appdrawer/MobileAppDrawer";

import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import HelpIcon from '@mui/icons-material/Help';
import FeedbackButton from "./FeedbackButton";
import NotificationMenu from "./notifiations/NotificationMenu";
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";
import {colors} from "../../themes/AppTheme";
import {ImpersonateUserContext} from "../../providers/ImpersonateUserProvider";
import { IntercomComponent } from "./IntercomComponent";
import useAccountApi from "../../hooks/account/Account";
import LogoutButton from "./LogoutButton";

interface StyledAppBarProps extends AppBarProps {
    open?: boolean;
    isAuthenticated: boolean;
    expired?: boolean;
}

const StyledAppBar = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isAuthenticated' && prop !== 'expired'
})<StyledAppBarProps>(({theme, open, isAuthenticated, expired}) => ({


    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && isAuthenticated && !expired &&{
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    ...(!open && isAuthenticated && !expired && {
        marginLeft: theme.spacing(7),
        width: `calc(100% - ${theme.spacing(7)} - 1px)`,
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(8),
            width: `calc(100% - ${theme.spacing(8)} - 1px)`,
        },
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

function RamAppBar(props: AppBarProps) {

    const auth: IAuthContext = useContext(AuthContext)
    const theme = useTheme();

    const { openDrawer, setOpenDrawer, preferencesLoaded } = useContext(UserPreferencesContext)
    const { impersonateUsername } = useContext(ImpersonateUserContext);
    const { account, refreshAccount } = useAccountApi()

    // Refresh account on token change needed because sometimes the auth token is not available yet when the component renders
    useEffect(() => {
        refreshAccount()
    }, [auth.token]);

    let intercomComponent = auth.token && (<IntercomComponent />);

    let drawerButton = auth.token && preferencesLoaded && account?.featurePackage !== 'EXPIRED' && <AppBarButton id="drawer-button" icon={openDrawer ? <MenuOpenIcon/> : <MenuIcon/>} onClick={() => setOpenDrawer(!openDrawer)}/>;

    let mobileDrawerButton = auth.token && preferencesLoaded && account?.featurePackage !== 'EXPIRED' && <AppBarButton id="mobile-drawer-button" icon={openDrawer ? <MenuOpenIcon/> : <MenuIcon/>} onClick={() => setIsMobileDrawerOpen(!isMobileDrawerOpen)}/>;

    let searchBar = auth.token && preferencesLoaded && account?.featurePackage !== 'EXPIRED' && <SearchBar />;

    let languageSelector = !auth.token && <LanguageSelector/>;

    let productSelector = auth.token && preferencesLoaded && account?.featurePackage !== 'EXPIRED' &&  <ProductSelector/>;

    let notificationDropdown = auth.token && account?.featurePackage !== 'EXPIRED' && preferencesLoaded && <NotificationMenu/>;

    let accountDropdown = auth.token && preferencesLoaded && account?.featurePackage !== 'EXPIRED' && <AccountMenu/>;

    let helpButton = auth.token && preferencesLoaded && <AppBarButton id="help-button" className="account_callsupport_link" icon={<HelpIcon/>} onClick={() => {}}/>;

    let feedbackButton = auth.token && account?.featurePackage !== 'EXPIRED' && preferencesLoaded && <FeedbackButton/>

    let logoutButton = auth.token && account?.featurePackage === 'EXPIRED' && preferencesLoaded && <LogoutButton/>

    let impersonateText = auth.token && preferencesLoaded &&
        <span style={{color: colors.ram_gray}}>
            You are now viewing as<strong style={{fontWeight: 'bold'}}> {impersonateUsername}</strong>
        </span>;

    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);

    const mobileNav = (<AppBar
        position="fixed"
        sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: theme.palette.appbar.background,
            borderBottom: "2px solid " + theme.palette.appbar.accent,
        }}
    >
        <Toolbar variant="dense">
            <Box sx={{flexGrow: 1}}>
                {mobileDrawerButton}
            </Box>
            {intercomComponent}
            {productSelector}
            {notificationDropdown}
            {accountDropdown}
            {helpButton}
            {feedbackButton}
            {languageSelector}
            {logoutButton}

            <MobileAppDrawer isMobileDrawerOpen={isMobileDrawerOpen} setIsMobileDrawerOpen={setIsMobileDrawerOpen}/>
        </Toolbar>
    </AppBar>)

    const desktopNav = (
        <StyledAppBar
            position="fixed"
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: theme.palette.appbar.background,
                borderBottom: "2px solid " + theme.palette.appbar.accent,
            }}
            open={auth.token ? openDrawer : false}
            isAuthenticated={!!(auth.token && preferencesLoaded)}
            expired={account?.featurePackage === 'EXPIRED'}
        >
            <Toolbar variant="dense" sx={{justifyContent: "flex-end"}}>
                <Box sx={{flexGrow: 1}}>
                    {drawerButton}
                </Box>

                {impersonateUsername && (
                    <Box sx={{flexGrow: 1}}>
                        {impersonateText}
                    </Box>)
                }
                {searchBar}
                {intercomComponent}
                {productSelector}
                {notificationDropdown}
                {accountDropdown}
                {helpButton}
                {feedbackButton}
                {languageSelector}
                {logoutButton}
            </Toolbar>
        </StyledAppBar>
    );

    if (isMobile) {
        return mobileNav;
    } else {
        return desktopNav;
    }
}

export default RamAppBar;
