import {Asset, AssetType} from "../hooks/assets/dto/Asset";

export function buildAssetStatusMessage(asset: Asset): string {
    switch (asset.status) {
        case 'STOPPED':
            return 'Stopped';
        case 'MOVING':
            if (asset.latestEvent) {
                return 'Moving ' + asset.latestEvent.headingIndicator + " at " + asset.latestEvent.speed.value + " " + asset.latestEvent.speed.units;
            }
            return 'Moving';
        case 'STATIONARY':
            return 'Stationary';
        default:
            return 'Unknown Status';
    }
}

export function doesAssetHaveProblem(asset: Asset): boolean {
    return asset.latestEvent?.location.goodGpsFix === false;
}

export function buildLastEventMessage(date: Date): string {
    const difference = Date.now() - date.getTime(); // difference in milliseconds
    const resultInMinutes = Math.round(difference / 60000);
    return "Last updated " + formatLastUpdateTime(resultInMinutes) + " ago";
}

function formatLastUpdateTime(minutes: number): string {
    if (minutes <= 60) {
        return `${minutes} mins`;
    } else if (minutes <= 1440) {
        const hours = Math.floor(minutes / 60);
        return `${hours} hour${hours > 1 ? 's' : ''}`;
    } else {
        const days = Math.floor(minutes / 1440);
        return `${days} day${days > 1 ? 's' : ''}`;
    }
}

export function isVehicle(assetType: AssetType) {
    const vehicleAsset = new Set([
        AssetType.MOTORCYCLE,
        AssetType.CAR,
        AssetType.VAN,
        AssetType.TRUCK
    ]);

    return vehicleAsset.has(assetType);
}

export function findAsset(assets: Asset[] | null, id: number) {
    return assets?.find(asset => asset.id === id);
}

export function filterGenerator(assets: Asset[]) {
    return assets.filter(asset => asset.type === AssetType.GENERATOR)
}

export function hasGeneratorAsset(assets: Asset[]): boolean {
    return assets.some(asset => asset.type === AssetType.GENERATOR);
}

export function getAssetName(asset: Asset | undefined) {
    return asset?.alias !=null && asset?.alias !== "" ? asset?.alias : asset?.identificationMark ?? 'Unknown';
}

