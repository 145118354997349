import {Box} from "@mui/material";
import ContentPanel from "../../components/content-panel/ContentPanel";
import FullView from "../../layouts/FullView";
import {Table, TableCell, TableRow} from "../../components/table/core/Table";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {RowDeleteButton, RowEditButton} from "../../components/table/core/TableButtons";
import ConfirmationDialog from "../../components/dialog/ConfirmationDialog";
import {useLiveshareApi} from "../../hooks/liveshare/Liveshare";
import {LiveShareLink} from "../../hooks/liveshare/dto/LiveShareLink";
import {Outlet, useNavigate} from "react-router-dom";
import {getAssetName} from "../../utils/AssetUtils";
import FilterBar from "../../components/filterbar/FilterBar";

export default function LivesharePage() {
    return (
        <FullView content={<Outlet/>}/>
    );

}

export function LiveshareListPage() {
    const navigate = useNavigate()

    const {t} = useTranslation();

    const [filter, setFilter] = useState("");

    const liveshareApi = useLiveshareApi(true);

    const columns = [
        { id:'assets', content: t("liveshare.admin.assets")},
        { id:'period', content: t("liveshare.admin.period")},
        { id:'start', content: t("liveshare.admin.start_time")},
        { id:'end', content: t("liveshare.admin.end_time")},
        { id:'link', content: t("liveshare.admin.link")},
        { id:'expires', content: t("liveshare.admin.expires")},
        { id:'actions', content: t("liveshare.admin.actions")}
    ];

    const links = liveshareApi.links || [];

    const rows = links
        .filter(shouldInclude)
        .map((link) => {
            return <LinkRow key={link.id} link={link} updateTrigger={liveshareApi.getAllLinks}/>
        });

    function shouldInclude(link: LiveShareLink) {
        if (filter === "") {
            return true;
        }

        return link.assets.some((asset) => {
            return asset.alias?.toLowerCase().includes(filter.toLowerCase()) ||
                asset.identificationMark.toLowerCase().includes(filter.toLowerCase())
        });

    }

    const content = <ContentPanel><Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "space-between",
        padding: '20px',
        paddingTop: '10px',
        height: '100%',
    }}>
        <FilterBar filter={filter} onSearchChange={setFilter} label={'Search for assets...'}/>
        <Table
            loading={liveshareApi.loading}
            id="liveshare"
            label="Liveshare"
            columns={columns}
            rows={rows}
            createAction={() => {
                navigate('/liveshare/create-liveshare')
            }}/>
    </Box></ContentPanel>

    return content;
}

interface LinkRowProps {
    link: LiveShareLink;
    updateTrigger: () => void;
}

function LinkRow(props: LinkRowProps) {
    const {t} = useTranslation();
    const navigate = useNavigate()

    const [showDeleteDialog, setShowDeleteDialog] = React.useState<boolean>(false)
    const [selectedLiveshareId, setSelectedLiveshareId] = React.useState<number | null>(null);

    const liveshareApi = useLiveshareApi(false);

    async function handleDelete() {
        if (selectedLiveshareId !== null) {
            await liveshareApi.deleteLink(selectedLiveshareId);
            setShowDeleteDialog(false);
            props.updateTrigger();
        }
    }

    return (
        <TableRow key={`liveshare-link-${props.link.id}`}>
            <TableCell>
                {props.link.assets.length > 3 && <>{props.link.assets.length + " Assets"}</>}

                {props.link.assets.length < 3 && props.link.assets.map((asset) =>
                    <>{getAssetName(asset)}</>)
                }
            </TableCell>

            <TableCell>
                    {props.link.linkPeriod}
            </TableCell>

            <TableCell>
                    {props.link.startTime || "N/A"}
            </TableCell>

            <TableCell>
                    {props.link.endTime || "N/A"}
            </TableCell>

            <TableCell>
                <a id={`liveshare-link-${props.link.id}`} href={props.link.url} className="text-link">
                    {props.link.url}
                </a>
            </TableCell>

            <TableCell>
                {props.link.expiresAt || "N/A"}
            </TableCell>

            <TableCell>
                <RowEditButton action={() => {
                    const selectedLink = props.link;
                    navigate('/liveshare/update-liveshare', { state: { selectedLink: selectedLink } });
                }} />
                <RowDeleteButton action={() => {
                    setSelectedLiveshareId(props.link.id);
                    setShowDeleteDialog(true);
                }}/>
                <ConfirmationDialog open={showDeleteDialog}
                                    title={t('liveshare.delete_dialog.title')}
                                    description={t('liveshare.delete_dialog.description')}
                                    confirmText={t('button.delete')} cancelText={t('button.cancel')}
                                    onConfirm={handleDelete}
                                    onCancel={() => setShowDeleteDialog(false)}/>
            </TableCell>
        </TableRow>);
}
