import {useEffect, useState} from "react";
import usePlatformFetch from "../../shared/PlatformFetch";
import PropertiesLoader from "../../../properties/PropertiesLoader";
import {ServiceSettingRequest} from "./dto/ServiceSettingRequest";
import {ServiceSetting} from "./dto/ServiceSetting";

const properties = PropertiesLoader();

interface ServiceSettingApiOptions {
    shouldLoadAll?: boolean;
    shouldPoll?: boolean;
    pollFrequency?: number;
}

export function useServiceSettingApi(props: ServiceSettingApiOptions = {}) {
    const {platformFetch, checkOk, checkCreated, checkNoContent} = usePlatformFetch();

    const [serviceSettings, setServiceSettings] = useState<ServiceSetting[]>([]);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const getAllServiceSettings = async () => {
        return new Promise((resolve, reject) => {
            setLoading(true)
            platformFetch(
                properties.platformApiEndpoint + "tracking/v1/servicing/settings",
                'get')
                .then(checkOk)
                .then(response => response.json())
                .then(serviceSettings => {
                    setServiceSettings(serviceSettings)
                    setLoading(false)
                    resolve(serviceSettings)
                })
                .catch((error) => {
                    console.error("Error getting service settings: " + error)
                    setError(error)
                    setLoading(false)
                    reject(error)
                });
        });
    }

    const createServiceSetting = async (serviceSettingRequest: ServiceSettingRequest) => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `tracking/v1/servicing/${serviceSettingRequest.vehicleId}/settings`,
                'post',
                JSON.stringify(serviceSettingRequest))
                .then(checkCreated)
                .then(response => response.json())
                .then(serviceSetting => resolve(serviceSetting))
                .catch((error) => {
                    console.error("Error creating service setting: " + error)
                    reject(error)
                });
        });
    }

    const updateServiceSetting = async (serviceSettingRequest: ServiceSettingRequest) => {
        return new Promise((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `tracking/v1/servicing/${serviceSettingRequest.vehicleId}/settings`,
                'put',
                JSON.stringify(serviceSettingRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(serviceSetting => resolve(serviceSetting))
                .catch((error) => {
                    console.error("Error updating service setting: " + error)
                    reject(error)
                });
        });
    }

    const deleteServiceSetting = async (assetId: string) => {
        return new Promise<void>((resolve, reject) => {
            platformFetch(
                properties.platformApiEndpoint + `tracking/v1/servicing/${assetId}/settings`,
                'delete')
                .then(checkNoContent)
                .then(() => resolve())
                .catch((error) => {
                    console.error("Error deleting service setting: " + error)
                    reject(error)
                });
        });
    }

    useEffect(() => {
        if (props.shouldLoadAll) {
            getAllServiceSettings();
            if (props.shouldPoll) {
                const interval = setInterval(getAllServiceSettings, props.pollFrequency ?? 30000);
                return () => clearInterval(interval);
            }
        }
    }, []);

    return {
        loading, error, serviceSettings, getAllServiceSettings, createServiceSetting, updateServiceSetting, deleteServiceSetting
    }
}
