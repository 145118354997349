import {MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {useTranslation} from "react-i18next";
import {colors} from "../../../themes/AppTheme";
import {ServicePeriodType} from "../../../enum/ServicePeriodType";

interface ServicePeriodTypeSelectionProps {
    selectedServicePeriodType: string;
    setSelectedServicePeriodType: (selectedServicePeriodType: ServicePeriodType) => void;
}

export default function ServicePeriodTypeSelection(props: ServicePeriodTypeSelectionProps) {
    const {t} = useTranslation();

    function handlePeriodTypeChange(event: SelectChangeEvent<any>) {
        props.setSelectedServicePeriodType(event.target.value);
    }

    const servicePeriodTypes = Object.values(ServicePeriodType).sort((a, b) => {
        const translationA = t(`service.service_settings.service_setting_table.${a.toLowerCase()}`);
        const translationB = t(`service.service_settings.service_setting_table.${b.toLowerCase()}`);
        return translationA.localeCompare(translationB);
    });

    return (
        <Select
            id="file_format"
            size="small"
            value={props.selectedServicePeriodType}
            color="info"
            style={{height: '100%', fontSize: '12px', color: colors.ram_purple}}
            onChange={handlePeriodTypeChange}
            fullWidth
        >
            {servicePeriodTypes.map((period: ServicePeriodType) => (
                <MenuItem key={period} style={{height: 25, fontSize: '12px'}} value={period}>
                    {t(`service.service_settings.service_setting_table.${period.toLowerCase()}`)}
                </MenuItem>
            ))}
        </Select>
    );
}
